import { createRouter, createWebHistory } from 'vue-router'
import Root from '../views/Root.vue'
import Apply from '../views/Apply.vue'
import Commissions from '../views/Commissions.vue'
import Analytics from '../views/Analytics.vue'
import Admin from '../views/Admin.vue'
import Profile from '../views/Profile.vue'
import AuthMiddleware from './auth.guard'

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Root
  },
  {
    path: '/apply',
    name: 'Apply',
    component: Apply
  },
  {
    path: '/commissions',
    name: 'Commissions',
    component: Commissions,
    meta: { isProtected: true }
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: Analytics,
    meta: { isProtected: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: { isProtected: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { isProtected: true }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

router.beforeEach(AuthMiddleware)

export default router
