<template>
  <div class="min-h-screen h-screen flex flex-col">
    <div class="mt-5">
      <select id="timeWindow" v-model="timeWindow">
        <option v-for="timeWindow in timeWindows" :key="timeWindow.id" :value="timeWindow.id">
          {{ timeWindow.name }}
        </option>
      </select>
    </div>

    <div class="mt-10 max-w-6xl w-2/3 mx-auto">
      <h1 class="text-2xl font-bold leading-tight text-gray-900">
        Commissions
      </h1>
      <LineChart ref="commissions" :data="commissionsData" :options="options" />
    </div>

    <div class="mt-10 max-w-6xl w-2/3 mx-auto">
      <h1 class="text-2xl font-bold leading-tight text-gray-900">
        Page views
      </h1>
      <LineChart ref="pageViews" :data="pageViewsData" :options="options" />
    </div>

    <div class="mt-10 max-w-6xl w-2/3 mx-auto">
      <h1 class="text-2xl font-bold leading-tight text-gray-900">
        Visitors
      </h1>
      <LineChart ref="visitors" :data="visitorsData" :options="options" />
    </div>
  </div>
</template>

<script>
import { TimeWindows } from '../lib/backend'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import sumBy from 'lodash/sumBy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { Line as LineChart } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend)

const WEBSITE_COLORS = {
  'Production-Ready Serverless': '#F5B922',
  'Testing Serverless Architectures': '#F57253',
  'AppSync Masterclass': '#4777F5'
}
const OTHER_COORS = [
  '#92EB31',
  '#EB5A31',
  '#3159EB',
  '#516096',
  '#5C6B4C',
  '#EBBA31',
  '#4F63AB',
  '#3159EB',
  '#968451'
]

export default {
  props: ['getCommissions', 'getVisitors', 'getPageViews'],
  components: {
    LineChart
  },
  data () {
    return {
      timeWindow: TimeWindows.Last24Hours,
      timeWindows: [
        { id: TimeWindows.Last24Hours, name: 'last 24 hours' },
        { id: TimeWindows.Last7Days, name: 'last 7 days' },
        { id: TimeWindows.Last30Days, name: 'last 30 days' },
        { id: TimeWindows.Last90Days, name: 'last 90 days' },
        { id: TimeWindows.Last365Days, name: 'last 365 days' }
      ],
      commissionsData: {
        labels: [],
        datasets: [{
          data: [...Array(24).keys()].map(x => 0)
        }]
      },
      pageViewsData: {
        labels: [],
        datasets: [{
          data: [...Array(24).keys()].map(x => 0)
        }]
      },
      visitorsData: {
        labels: [],
        datasets: [{
          data: [...Array(24).keys()].map(x => 0)
        }]
      },
      options: {
        responsive: true
      }
    }
  },
  watch: {
    async timeWindow (newValue, oldValue) {
      await Promise.all([
        this.loadAnalyticsData(this.getCommissions, 'commissions'),
        this.loadAnalyticsData(this.getPageViews, 'pageViews'),
        this.loadAnalyticsData(this.getVisitors, 'visitors', false)
      ])
    }
  },
  methods: {
    getWebsiteLabel (website) {
      switch (website) {
        case 'AppSync Masterclass - learn modern full-stack development':
          return 'AppSync Masterclass'
        default:
          return website
      }
    },
    getLabels (timeWindow) {
      switch (timeWindow) {
        case TimeWindows.Last24Hours:
          return [...Array(24).keys()]
            .map(n => moment().add(-23 + n, 'hour').format('YYYY-MM-DD HH:00:00.000000000'))
        case TimeWindows.Last7Days:
          return [...Array(7).keys()]
            .map(n => moment().add(-6 + n, 'day').format('YYYY-MM-DD 00:00:00.000000000'))
        case TimeWindows.Last30Days:
          return [...Array(30).keys()]
            .map(n => moment().add(-29 + n, 'day').format('YYYY-MM-DD 00:00:00.000000000'))
        case TimeWindows.Last90Days:
          return [...Array(90).keys()]
            .map(n => moment().add(-89 + n, 'day').format('YYYY-MM-DD 00:00:00.000000000'))
        case TimeWindows.Last365Days:
          return [...Array(12).keys()]
            .map(n => moment().add(-11 + n, 'month').format('YYYY-MM-01 00:00:00.000000000'))
      }
    },
    async loadAnalyticsData (getAnalyticsData, chartName, generateCombined = true) {
      const labels = this.getLabels(this.timeWindow)
      const charLabels =
        this.timeWindow === TimeWindows.Last24Hours
          ? labels.map(x => x.substring(0, 16))
          : labels.map(x => x.substring(0, 10))

      const datasets = []
      const otherColors = OTHER_COORS.map(x => x)
      const resp = await getAnalyticsData(this.timeWindow)
      const byWebsite = groupBy(resp.dataset, x => x.series)
      const websiteNames = Object.keys(byWebsite).sort()
      for (const website of websiteNames) {
        const datum = byWebsite[website]
        const data = labels.map(timestamp => {
          const datapoint = datum.find(x => x.timestamp === timestamp)
          return datapoint?.value || 0
        })

        const label = this.getWebsiteLabel(website)
        const color = WEBSITE_COLORS[label] || otherColors.pop()
        datasets.push({
          label,
          backgroundColor: color,
          borderColor: color,
          data
        })
      }

      if (generateCombined) {
        const data = [...labels.keys()].map(idx =>
          sumBy(datasets, x => x.data[idx])
        )
        datasets.push({
          label: 'Combined',
          backgroundColor: 'black',
          borderColor: 'black',
          data
        })
      }

      if (datasets) {
        this.$refs[chartName].chart.data.labels = charLabels
        this.$refs[chartName].chart.data.datasets = datasets
        this.$refs[chartName].chart.update()
      }
    }
  },
  async created () {
    await Promise.all([
      this.loadAnalyticsData(this.getCommissions, 'commissions'),
      this.loadAnalyticsData(this.getPageViews, 'pageViews'),
      this.loadAnalyticsData(this.getVisitors, 'visitors', false)
    ])
  }
}
</script>
