<template>
  <div class="min-h-screen h-screen flex flex-col">
    <top-banner v-bind:pageName="pageName"/>

    <div class="py-10">
      <AnalyticsGraphs
          v-bind:getCommissions="getCommissions"
          v-bind:getPageViews="getPageViews"
          v-bind:getVisitors="getVisitors" />
    </div>
  </div>
</template>

<script>
import TopBanner from '../components/TopBanner.vue'
import AnalyticsGraphs from '@/components/AnalyticsGraphs.vue'
import { getPageViews, getVisitors, getCommissions } from '../lib/backend'

export default {
  name: 'AnalyticsView',
  components: {
    TopBanner,
    AnalyticsGraphs
  },
  data () {
    return {
      pageName: 'analytics',
      getPageViews,
      getVisitors,
      getCommissions
    }
  }
}
</script>
