import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import { Amplify } from 'aws-amplify'

Amplify.configure({
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.VUE_APP_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_headers: async () => {
      const session = await Amplify.Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken()
      }
    }
  },
  Auth: {
    region: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true
  }
})

createApp(App).use(router).mount('#app')
