<template>
  <div class="min-h-screen bg-white">
    <top-banner v-bind:pageName="pageName" />

    <div class="py-10">
      <header class="flex justify-center">
        <div class="w-1/2 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">
            Your profile
          </h1>
        </div>
      </header>
    </div>

    <div class="mt-5 mb-28 min-w-full flex justify-center">
      <dl class="divide-y divide-gray-200 w-1/2">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Full name
          </dt>
          <dd class="mt-1 text-left flex text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="mt-1 flex-grow">{{ affiliateName }}</span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Email address
          </dt>
          <dd class="mt-1 flex text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="mt-1 flex-grow">{{ affiliateEmail }}</span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Commission Rate
          </dt>
          <dd class="mt-1 flex text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="mt-1 flex-grow">{{ commissionRate }}%</span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Coupon ({{ couponRate }}% off)
          </dt>
          <dd class="mt-1 flex text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="mt-1 flex-grow">{{ coupon }}</span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Affiliate id
          </dt>
          <dd class="mt-1 flex text-gray-900 sm:mt-0 sm:col-span-2">
            <span class="flex-grow">{{ affiliateId }}</span>
          </dd>
        </div>
        <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
          <dt class="text-gray-500">
            Affiliate URLs
          </dt>
        </div>
        <div class="text-left pt-2 pl-4 grid grid-cols-[3fr_6fr_1fr] gap-y-4">
          <span class="font-bold text-sm">AppSync Masterclass</span>
          <span ref="asm-link" class="text-sm">https://appsyncmasterclass.com/?affiliateId={{ affiliateId }}</span>
          <button @click="copyText(`https://appsyncmasterclass.com/?affiliateId=${affiliateId}`)" class="ml-2 bg-slate-100 hover:bg-slate-200 font-bold py-2 px-4 rounded">
            <i class="fas fa-copy"></i>
          </button>

          <span class="font-bold text-sm">Testing Serverless Architectures</span>
          <span ref="tsa-link" class="text-sm">https://testserverlessapps.com/?affiliateId={{ affiliateId }}</span>
          <button @click="copyText(`https://testserverlessapps.com/?affiliateId=${affiliateId}`)" class="ml-2 bg-slate-100 hover:bg-slate-200 font-bold py-2 px-4 rounded">
            <i class="fas fa-copy"></i>
          </button>

          <span class="font-bold text-sm">Production-Ready Serverless</span>
          <span ref="prsls-link" class="text-sm">https://productionreadyserverless.com/?affiliateId={{ affiliateId }}</span>
          <button @click="copyText(`https://productionreadyserverless.com/?affiliateId=${affiliateId}`)" class="ml-2 bg-slate-100 hover:bg-slate-200 font-bold py-2 px-4 rounded">
            <i class="fas fa-copy"></i>
          </button>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import TopBanner from '../components/TopBanner.vue'
import { getMyProfile } from '../lib/backend'

export default {
  name: 'ProfileView',
  components: {
    TopBanner
  },
  data () {
    return {
      pageName: 'profile',
      affiliateName: undefined,
      affiliateEmail: undefined,
      commissionRate: undefined,
      affiliateId: undefined,
      coupon: undefined,
      couponRate: undefined
    }
  },
  async created () {
    const profile = await getMyProfile()

    this.affiliateName = profile.affiliateName
    this.affiliateEmail = profile.affiliateEmail
    this.commissionRate = profile.commissionRate * 100
    this.affiliateId = profile.affiliateId
    this.coupon = profile.coupon
    this.couponRate = profile.couponRate * 100
  },
  methods: {
    async copyText (text) {
      await navigator.clipboard.writeText(text)
    }
  }
}
</script>

<style scoped>
  span {
    text-align: left;
    padding-left: 0.25rem;
  }

  dt {
    text-align: left;
    font-weight: 500;
    padding-left: 0.25rem;
  }

  dd {
    text-align: left;
    font-weight: 500;
    padding-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  input {
    padding-left: 0.25rem;
  }
</style>
