<template>
  <div class="min-h-screen h-screen">
    <top-banner v-bind:pageName="pageName"/>

    <div class="py-10">
      <header class="flex justify-center">
        <div class="w-1/2 pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">
            Commissions - {{this.unpaidCommissions || 0}} (unpaid)
          </h1>
        </div>
      </header>
    </div>

    <div class="overflow-y-scroll flex justify-center px-2">
      <table class="max-w-4xl min-w-fit w-4/5 text-left align-top text-sm lg:text-lg">
        <tr class="align-top">
          <th class="mx-2">Product</th>
          <th class="min-w-fit hidden lg:block mx-2">Net Amount</th>
          <th class="min-w-fit mx-2">Commission</th>
          <th class="min-w-fit hidden sm:block mx-2">Timestamp</th>
        </tr>
        <tr v-bind:key="commission.orderId" v-for="commission in commissions">
          <td class="mx-2">{{ commission.courseName }}</td>
          <td class="min-w-fit hidden lg:block mx-2">{{ commission.netAmount }} {{ commission.currency }}</td>
          <td class="min-w-fit mx-2">{{ commission.commission }} {{ commission.currency }}</td>
          <td class="min-w-fit hidden sm:block mx-2">{{ commission.purchasedAt }}</td>
        </tr>
      </table>
    </div>

    <button
      @click="loadCommissions"
      v-if="nextToken"
      class="focus:outline-none font-bold my-10"
    >load more...</button>
  </div>
</template>

<script>
import TopBanner from '../components/TopBanner.vue'
import { getAccountSummary, listCommissions } from '../lib/backend'

export default {
  name: 'CommissionsView',
  components: {
    TopBanner
  },
  data () {
    return {
      pageName: 'commissions',
      unpaidCommissions: 0,
      commissions: [],
      nextToken: undefined
    }
  },
  methods: {
    async loadSummary () {
      const { unpaidCommissions, commissions } = await getAccountSummary()
      this.unpaidCommissions = unpaidCommissions
      this.commissions = commissions.commissions
      this.nextToken = commissions.nextToken
    },

    async loadCommissions () {
      const { commissions, nextToken } = await listCommissions(this.nextToken)
      this.commissions = this.commissions.concat(commissions)
      this.nextToken = nextToken
    }
  },
  async created () {
    await this.loadSummary()
  }
}
</script>
