<template>
  <div class="min-h-screen h-screen flex flex-col">
    <top-banner v-bind:pageName="pageName"/>

    <div class="mt-5">
      <select id="affiliate" v-model="currentAffiliateId">
        <option v-for="affiliate in affiliates" :key="affiliate.affiliateId" :value="affiliate.affiliateId">
          {{ affiliate.affiliateName }}
        </option>
      </select>
    </div>

    <div v-if="currentAffiliateId" class="py-10">
      <AnalyticsGraphs
          v-bind:getCommissions="getCommissions"
          v-bind:getPageViews="getPageViews"
          v-bind:getVisitors="getVisitors"
          v-bind:key="currentAffiliateId" />
    </div>
  </div>
</template>

<script>
import TopBanner from '../components/TopBanner.vue'
import AnalyticsGraphs from '@/components/AnalyticsGraphs.vue'
import {
  listAffiliates,
  getAffiliatePageViews,
  getAffiliateVisitors,
  getAffiliateCommissions
} from '../lib/backend'

export default {
  name: 'AdminView',
  components: {
    TopBanner,
    AnalyticsGraphs
  },
  data () {
    return {
      pageName: 'admin',
      affiliates: [{
        affiliateId: null,
        affiliateName: 'Select affiliate'
      }],
      currentAffiliateId: null,
      getPageViews: (x) => getAffiliatePageViews(this.currentAffiliateId, x),
      getVisitors: (x) => getAffiliateVisitors(this.currentAffiliateId, x),
      getCommissions: (x) => getAffiliateCommissions(this.currentAffiliateId, x)
    }
  },
  watch: {
    async currentAffiliateId (newValue, oldValue) {
    }
  },
  async mounted () {
    const { affiliates } = await listAffiliates()
    this.affiliates = [...this.affiliates, ...affiliates]
  }
}
</script>
