<template>
  <div class="min-h-screen flex justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 self-center">
      <div>
        <img class="mx-auto h-32 w-auto" src="@/assets/cat-logo.png" alt="affiliate tracking">
        <h1 class="text-center text-lg text-gray-900">
          theburningmonk's affiliate tracking
        </h1>

        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <form class="mt-8 space-y-6" v-on:submit.prevent='noop'>
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input
              v-model="email"
              type="text"
              :class="`appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm ${this.loginStep !== this.steps.SIGN_IN ? 'disabled:opacity-75' : '' }`"
              placeholder="Email address">
          </div>
        </div>

        <div
          v-if="loginStep === steps.CUSTOM_CHALLENGE"
          class="rounded-md shadow-sm -space-y-px">
          <div>
            <p class="mb-5">Please check your email for your secret code.</p>

            <label for="email-address" class="sr-only">Secret Code</label>
            <input
              v-model="secretCode"
              type="text"
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Enter your secret code">
          </div>
        </div>

        <div>
          <button
            v-if="loginStep === steps.SIGN_IN"
            @click="signIn"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Passwordless sign in
          </button>

          <button
            v-if="loginStep === steps.CUSTOM_CHALLENGE"
            @click="answerCustomChallenge"
            class="group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
              </svg>
            </span>
            Submit
          </button>
        </div>
      </form>

      <div>
        <p class="mt-10 text-lg w-full">
          Earn <strong>50% commission</strong> on these courses.
          <a class="underline font-semibold" href="/apply">Apply to join.</a>
        </p>

        <div class="flex space-x-4">
          <a target="_blank" href="https://appsyncmasterclass.com/?utm_campaign=affiliate-portal&utm_source=login-page">
            <img class="mx-auto h-32 w-32" src="@/assets/asm-logo-square.png" />
          </a>
          <a target="_blank" href="https://testserverlessapps.com/?utm_campaign=affiliate-portal&utm_source=login-page">
            <img class="mx-auto h-32 w-32" src="@/assets/tsa-logo-square.png" />
          </a>
          <a target="_blank" href="https://productionreadyserverless.com/?utm_campaign=affiliate-portal&utm_source=login-page">
            <img class="mx-auto h-32 w-32" src="@/assets/prsls-logo-square.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  .tooltip {
    display: block !important;
    z-index: 10000;
  }

  .tooltip .tooltip-inner {
    background: black;
    color: white;
    border-radius: 6px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip.popover .popover-inner {
    background: #f9f9f9;
    color: black;
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, .1);
  }

  .tooltip.popover .popover-arrow {
    border-color: #f9f9f9;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
</style>

<script>
import { Auth } from 'aws-amplify'
import router from '../router/index'

export default {
  name: 'RootView',
  components: {
  },
  data () {
    const LOGIN_STEPS = {
      SIGN_IN: 'SIGN_IN',
      CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE'
    }

    return {
      email: '',
      secretCode: '',
      steps: LOGIN_STEPS,
      loginStep: LOGIN_STEPS.SIGN_IN,
      attemptsLeft: 3
    }
  },
  async created () {
    const user = await Auth.currentUserInfo()
    if (user) {
      router.push('/commissions')
    }
  },
  methods: {
    noop () {
    },

    async signIn () {
      try {
        this.cognitoUser = await Auth.signIn(this.email)
        console.log(this.cognitoUser)

        this.loginStep = this.steps.CUSTOM_CHALLENGE
      } catch (error) {
        alert(error.message)
      }
    },

    async answerCustomChallenge () {
      // Send the answer to the User Pool
      // This will throw an error if it’s the 3rd wrong answer
      this.cognitoUser = await Auth
        .sendCustomChallengeAnswer(this.cognitoUser, this.secretCode)
        .catch(err => {
          console.log(err)

          this.loginStep = this.steps.SIGN_IN
          this.attemptsLeft = 3
          this.email = ''
          this.secretCode = ''

          alert('Unauthorized.')

          throw err
        })

      // It we get here, the answer was sent successfully,
      // but it might have been wrong (1st or 2nd time)
      // So we should test if the user is authenticated now
      try {
        // This will throw an error if the user is not yet authenticated:
        await Auth.currentSession()

        router.push('/commissions')
      } catch {
        alert(`The code you entered is incorrect. ${--this.attemptsLeft} attempts left.`)
      }
    }
  }
}
</script>
