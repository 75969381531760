import { Auth } from 'aws-amplify'

export default async (to, from, next) => {
  const isProtected = to.matched.some(route => route.meta.isProtected)
  const isLoggedIn = await Auth.currentUserInfo()

  if (isProtected && !isLoggedIn) {
    next('/')
    return
  }

  next()
}
