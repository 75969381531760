import { API } from 'aws-amplify'
import gql from 'graphql-tag'

const getMyProfile = async () => {
  const result = await API.graphql({
    query: gql`
      query getMyProfile {
        getMyProfile {
          affiliateName
          affiliateEmail
          commissionRate
          affiliateId
          coupon
          couponRate
        }
      }
    `,
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getMyProfile
}

const getAccountSummary = async () => {
  const result = await API.graphql({
    query: gql`
      query getAccountSummary {
        getAccountSummary {
          unpaidCommissions
          commissions {
            nextToken
            commissions {
              orderId
              courseName
              currency
              purchasedAt
              netAmount
              commission
              customerCountry
            }
          }
        }
      }
    `,
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAccountSummary
}

const listCommissions = async (nextToken) => {
  const result = await API.graphql({
    query: gql`
      query listCommissions($nextToken: String) {
        listCommissions(nextToken: $nextToken) {
          nextToken
          commissions {
            orderId
            courseName
            currency
            purchasedAt
            netAmount
            commission
            customerCountry
          }
        }
      }
    `,
    variables: {
      nextToken
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.listCommissions
}

const getPageViews = async (timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAnalyticsData($timeWindow: TimeWindow!) {
        getAnalyticsData(
          metricType: PageViews
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAnalyticsData
}

const getAffiliatePageViews = async (affiliateId, timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAffiliateAnalyticsData($affiliateId: ID!, $timeWindow: TimeWindow!) {
        getAffiliateAnalyticsData(
          affiliateId: $affiliateId
          metricType: PageViews
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      affiliateId,
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAffiliateAnalyticsData
}

const getVisitors = async (timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAnalyticsData($timeWindow: TimeWindow!) {
        getAnalyticsData(
          metricType: Visitors
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAnalyticsData
}

const getAffiliateVisitors = async (affiliateId, timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAffiliateAnalyticsData($affiliateId: ID!, $timeWindow: TimeWindow!) {
        getAffiliateAnalyticsData(
          affiliateId: $affiliateId
          metricType: Visitors
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      affiliateId,
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAffiliateAnalyticsData
}

const getCommissions = async (timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAnalyticsData($timeWindow: TimeWindow!) {
        getAnalyticsData(
          metricType: Commissions
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAnalyticsData
}

const getAffiliateCommissions = async (affiliateId, timeWindow) => {
  const result = await API.graphql({
    query: gql`
      query getAffiliateAnalyticsData($affiliateId: ID!, $timeWindow: TimeWindow!) {
        getAffiliateAnalyticsData(
          affiliateId: $affiliateId
          metricType: Commissions
          timeWindow: $timeWindow
        ) {
          dataset {
            series
            timestamp
            value
          }
        }
      }
    `,
    variables: {
      affiliateId,
      timeWindow
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.getAffiliateAnalyticsData
}

const listAffiliates = async (nextToken) => {
  const result = await API.graphql({
    query: gql`
      query listAffiliates($nextToken: String) {
        listAffiliates(nextToken: $nextToken) {
          nextToken
          affiliates {
            affiliateName
            affiliateEmail
            commissionRate
            coupon
            couponRate
            affiliateId
          }
        }
      }
    `,
    variables: {
      nextToken
    },
    authMode: 'AMAZON_COGNITO_USER_POOLS'
  })

  return result.data.listAffiliates
}

const TimeWindows = {
  Last24Hours: 'Last24Hours',
  Last7Days: 'Last7Days',
  Last30Days: 'Last30Days',
  Last90Days: 'Last90Days',
  Last365Days: 'Last365Days'
}

export {
  getMyProfile,
  getAccountSummary,
  listCommissions,
  getPageViews,
  getAffiliatePageViews,
  getVisitors,
  getAffiliateVisitors,
  getCommissions,
  getAffiliateCommissions,
  listAffiliates,
  TimeWindows
}
