<template>
  <div class="mt-20 flex flex-col justify-center">
    <img class="mx-auto h-32 w-auto" src="@/assets/cat-logo.png" alt="affiliate tracking">
    <p class="mt-2 text-xl">Apply to join my affiliate program.</p>

    <form v-if="!hasApplied" class="place-self-center w-80 mt-8 space-y-2"
          @submit.prevent="submit">
      <div>
        <label for="name" class="sr-only">Name</label>
        <input
          type="text" v-model="name" placeholder="Name (required)" required
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="email-address" class="sr-only">Email address</label>
        <input
          type="email" v-model="email" placeholder="Email address (required)" required
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="twitter" class="sr-only">Twitter handle</label>
        <input
          type="text" v-model="twitter" placeholder="Twitter handle"
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="linkedin" class="sr-only">LinkedIn profile</label>
        <input
          type="text" v-model="linkedin" placeholder="LinkedIn profile"
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="youtube" class="sr-only">YouTube channel</label>
        <input
          type="text" v-model="youtube" placeholder="YouTube channel"
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="tiktok" class="sr-only">TikTok handle</label>
        <input
          type="text" v-model="tiktok" placeholder="TikTok handle"
          class="appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
      </div>

      <div>
        <label for="message" class="sr-only">Tell me about yourself, why are you interested in this affiliate program?</label>
        <textarea
          type="text" v-model="message" required
          placeholder="Tell me about yourself, why are you interested in this affiliate program?"
          class="h-32 appearance-none rounded-none w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm">
        </textarea>
      </div>

      <div>
        <button
          type="submit"
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Submit
        </button>
      </div>
    </form>

    <div v-if="hasApplied" class="place-self-center">
      <p class="mt-10 text-xl">
        Thank you! Your application has been received.
        <br>
        I will reach out to you soon with next steps.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplyView',
  data () {
    return {
      hasApplied: false,
      name: '',
      email: '',
      twitter: '',
      linkedin: '',
      youtube: '',
      tiktok: '',
      message: ''
    }
  },
  methods: {
    async submit () {
      try {
        const response = await fetch('https://restapi.affiliates.theburningmonk.com/apply', {
          method: 'POST',
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            twitter: this.twitter,
            linkedin: this.linkedin,
            youtube: this.youtube,
            tiktok: this.tiktok,
            message: this.message
          })
        })

        if (response.status === 202) {
          console.log('Success')
          this.hasApplied = true
        } else {
          throw new Error('Failed to submit application.')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }
}
</script>
