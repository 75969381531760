<template>
  <nav class="bg-white border-b border-gray-200">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="-my-px ml-6 flex space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a href="/commissions" :class="`${ this.pageName === 'commissions' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`">
              Commissions
            </a>
          </div>
          <div class="hidden sm:flex -my-px ml-6 space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a href="/analytics" :class="`${ this.pageName === 'analytics' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`">
              Analytics
            </a>
          </div>
          <div v-if="isAdmin" class="hidden sm:flex -my-px ml-6 space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a href="/admin" :class="`${ this.pageName === 'admin' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`">
              Admin
            </a>
          </div>
          <div class="-my-px ml-6 flex space-x-8">
            <a href="/profile" :class="`${ this.pageName === 'profile' ? 'border-indigo-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700' } inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`">
              Profile
            </a>
          </div>
        </div>
        <div class="-my-px ml-6 flex place-items-center space-x-8">
          <a @click="signOut" href="#" class="inline-flex items-center px-1 pt-1 text-sm font-medium">
            Sign out
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Auth } from 'aws-amplify'
import router from '../router/index'

export default {
  name: 'TopBanner',
  props: ['pageName'],
  data () {
    return {
      isAdmin: false
    }
  },
  methods: {
    async signOut () {
      try {
        await Auth.signOut({ global: true })
        console.log('signed out')

        router.push('/')
      } catch (error) {
        alert('Error signing out, please check console for error detail')
        console.log('error signing out', error)
      }
    }
  },
  async mounted () {
    const userSession = await Auth.currentSession()
    const groups = userSession?.idToken?.payload?.['cognito:groups'] || []
    if (groups.find(x => x === 'Admins')) {
      console.log('user is admin')
      this.isAdmin = true
    }
  }
}
</script>
